@import '~@reasoncorp/kyber-js/dist/scss/themes/misuite-v2/theme-misuite-v2';

.Paginator.row {
  margin-left: 15px;
}

.nav-item.active .nav-link {
  color: $secondary;
  padding-bottom: calc(1rem - 11px);
}

.nav-item.active {
  border-bottom: 3px solid $mi-primary-color;
}

.table-bordered tr.border-top-3 th, .table-bordered tr.border-top-3 td {
  border-top: 4px solid #dee2e6 !important;
}

.row-border-top-3 {
  padding-top: 1rem;
  margin-top: 1rem;
  border-top: 5px solid $grey-30;
}

.line-height-1 {
  line-height: 1;
}

.DenialReasonInformation {
  tbody th {
    border-bottom: 1px solid rgba(108, 117, 125, 0.35);
  }
}

@media print {
  @page {
    margin: 0.4cm;
  }

  a, button {
    color: #000;
    text-decoration: none !important;
  }

  a[href]:after {
    content: none !important;
  }

  .hide-print, .Footer, button {
    display: none !important;
  }

  .table td, .table th, .table-bordered th, .table-bordered td, table thead tr:only-child, table tbody tr:only-child {
    border: 1px solid #000 !important;
  }

  .table-bordered tr.border-top-3 th, .table-bordered tr.border-top-3 td {
    border-top: 2px solid #000 !important;
  }

  .table thead th {
    border-bottom: 1px solid #000 !important;
  }

  .col-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xl-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .card-header {
    border: 1px solid #000;
    border-bottom-width: 0;
  }

  .card-header, th {
    color: black;
  }

  .card {
    border-radius: 0;
    box-shadow: none !important;
  }

  .card > .table-responsive, .card > .table-responsive .table, .card > .table-responsive tbody, .card > .table-responsive tbody tr:last-child {
    border-radius: 0 !important;
  }

  .card-header:first-child {
    border-radius: 0;
  }

  table,
  table tr td,
  table tr th,
  .card,
  .card-header,
  .row {
    page-break-inside: avoid;
  }
}